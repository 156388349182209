import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Responsive } from "../theme/styles"
import {
  Text,
  Column,
  Wrapper,
  Container,
  SEOTitle,
  Button,
} from "../theme/index"
import { StaticImage } from "gatsby-plugin-image"
import { Modal } from "./modal"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  @media (max-width: 769px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    // flex-direction: column;
  }
`
const StyledColumn = styled(Column)`
  ${Responsive.sm`
    width: 100%;
    margin: 00px auto 0 auto;
  `}
  ${Responsive.lg`
  width: 75%;
`}
`

export const About = () => {
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  return (
    <Wrapper gray id="about">
      <Container>
        <Grid>
          <Column>
            <SEOTitle textAlign="center">
              Meet Your Trainer: Jon Bolden
            </SEOTitle>
            <Text margin="20px 0 10px 0">
              For as long as I can remember I wanted to be the best! Being from
              a small town in Virginia, that meant training in ways that most
              others couldn’t or wouldn’t. I excelled in basketball, went to
              college on scholarship, played overseas for a time but after a
              career altering injury I knew it was time to find a different
              sport. I found Boxing and went Pro!
            </Text>
            <Text margin="0px 0 10px 0">
              My passion for sports, love of training and deep desire to get my
              own family physically fit led me to become a personal trainer and
              I never looked back. I love helping working parents find time to
              get stronger and healthier for not only themselves but for their
              loved ones as well. When you show your kids that being fit is
              important to you, you plant the seeds of health in them. As a
              father and in this current climate, I believe our health has never
              been more of an essential priority.
            </Text>
            <Text>
              If you’re ready to take control of your health and the well-being
              of your family, then contact me today! There is no goal too small
              or too big that we can’t meet together. You bring the hunger for
              change, I’ll bring the motivation and the expertise. Whether we
              utilize strength training, cardio, basketball and boxing drills or
              any combination of those, you will get results that far exceed
              your expectations. So contact me today!
            </Text>
            <Button margin="20px 0 0 0" onClick={() => toggleModal()}>
              Book Class
            </Button>
          </Column>
          <StyledColumn>
            <StaticImage
              src="../images/trainerphoto.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `1.45rem` }}
            />
          </StyledColumn>
        </Grid>
      </Container>
      <Modal show={show} toggleModal={toggleModal}>
        <div
          className="fadeIn calendly-inline-widget"
          data-url="https://calendly.com/bokingperformancefitness"
          style={{
            minWidth: 320,
            height: 630,
            width: "100%",
            // minHeight: "100%",
            // marginBottom: "4em",
            // marginTop: "30px",
          }}
        ></div>
      </Modal>
    </Wrapper>
  )
}
