import React from "react"
import styled from "styled-components"
import { SEOTitle, Column } from "../theme/index"
import { Responsive } from "../theme/styles"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ContactForm } from "./contactform"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  @media (max-width: 769px) {
    display: block;
    // flex-direction: column;
  }
`

const StyledImg = styled(BgImage)`
  ${Responsive.sm`
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 50px 20px;
  flex-direction: column;
  &::after {
    background-position: center;
    background-size: contain;
  }
  `}
  ${Responsive.lg`
  padding: 50px;
  `}
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    margin-top: 30px;
  `}
  ${Responsive.lg`
  margin-top: 0px;
`}
`

export const Cta = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "cta.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const pluginImage = getImage(placeholderImage)

  return (
    <StyledImg image={pluginImage} id="cta">
      <Grid>
        <Column width="60%" margin="0 auto">
          <StaticImage
            src="../images/trainerphoto.png"
            quality={95}
            formats={["auto", "webp"]}
            alt="A Gatsby astronaut"
          />
        </Column>
        <StyledColumn>
          <SEOTitle textAlign="center" white>
            Get Started With A Free Consultation
          </SEOTitle>
          <ContactForm />
        </StyledColumn>
      </Grid>
    </StyledImg>
  )
}
// <Column>
//   <SEOTitle white>Get Started With A Free Training Session</SEOTitle>
//   <Line />
//   <Text white>Push Your Limits</Text>
//   <Button margin="15px">Sign Up Now</Button>
// </Column>
