import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  Text,
  Column,
  SEOTitle,
  Wrapper,
  Subtitle,
  Button,
} from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { graphql, useStaticQuery } from "gatsby"
import {
  IoMdFitness,
  IoMdBasketball,
  IoMdLaptop,
  IoMdNutrition,
} from "react-icons/io"
import { GiBoxingGlove } from "react-icons/gi"
import { Modal } from "./index"

const Grid = styled.div`
  ${Responsive.sm`
width: 100%;
max-width: 100%;
display: block;
`}
  //   ${Responsive.lg`
//   width: 100%;
// max-width: 100%;
// display: grid;
// grid-gap: 35px;
//   grid-template-columns: repeat(3, 450px 250px 250px);
//   `}
  ${Responsive.xlg`
//   grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
grid-template-columns: repeat(3, 600px 400px 400px);
grid-gap: 15px;
display: grid;
`}
`

const StyledImg = styled(BgImage)`
  ${Responsive.sm`
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 75px;
  flex-direction: column;
  &::after {
    background-position: center;
    background-size: contain;
  }
  `}
  ${Responsive.lg`
  padding: 50px;
  `}
`

const Line = styled.div`
  height: 10px;
  width: 50%;
  margin: 20px auto 20px auto;
  background: #fff;
`

const Box = styled.div`
  ${Responsive.sm`
  width: 100%;
  height: 100%;
  padding: 50px;
  border: none;
  background: ${Colors.red};
  `}
  ${Responsive.lg`
  border: 2px solid #000;
  `}
`

const TextBox = styled.div`
  border: 2px solid black;
  width: 100%;
  background: ${Colors.red};
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`

const BoxButton = styled(Button)`
  ${Responsive.sm`
    width: 90%;
    margin: 15px auto 0 auto;
  `}
  ${Responsive.md`
  width: 50%;
  margin: 15px auto 0 auto;
`}
`

export const Benefits = () => {
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "boking.jpeg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const pluginImage = getImage(placeholderImage)

  return (
    <Wrapper id="services">
      <Grid>
        <StyledImg image={pluginImage}>
          <TextBox>
            <SEOTitle textAlign="center" white>
              Test Your Boundaries
            </SEOTitle>
            <Line />
            <Text fontweight="bold" white>
              Sign up today to push your limits
            </Text>
            <BoxButton
              margin="15px"
              background={Colors.black}
              color={Colors.white}
              onClick={() => toggleModal()}
            >
              Book Class
            </BoxButton>
          </TextBox>
        </StyledImg>
        <Box>
          <Column>
            <IoMdFitness size={40} color={Colors.black} />
            <Subtitle margin="15px 0">Service 1: Slim and Tone</Subtitle>
            <Text>
              A customized regimen that will have you efficiently burning
              calories while building muscle utilizing strength training,
              cardio, circuit training various boxing drills.
            </Text>
          </Column>
          <Column margin="50px 0 0 0">
            <GiBoxingGlove size={40} color={Colors.black} />
            <Subtitle margin="15px 0">Service 2: Boxing</Subtitle>
            <Text>
              All levels welcomed. Whether you are looking to let off some
              steam, learn self-defense, or get in the ring this program is for
              you!
            </Text>
          </Column>
          <Column margin="50px 0 0 0">
            <IoMdBasketball size={40} color={Colors.black} />
            <Subtitle margin="15px 0">Service 3: Basketball</Subtitle>
            <Text>
              For those looking to improve their ball handling skills, build
              their lung capacity, increase their stamina and be able to bring
              an all over explosiveness to the court.
            </Text>
          </Column>
        </Box>
        <Box>
          <Column>
            <IoMdLaptop size={40} color={Colors.black} />
            <Subtitle margin="15px 0">Service 4: Virtual Training</Subtitle>
            <Text>
              Train on your own but never alone. Work out how you want.
            </Text>
          </Column>
          <Column margin="50px 0 0 0">
            <IoMdNutrition size={40} color={Colors.black} />
            <Subtitle margin="15px 0">
              Service 5: Nutritional Supplements
            </Subtitle>
            <Text>
              Looking for clean label vitamins, weight-loss aids and supplements
              to support your healthy diet? Click the link to set up a free
              acct, get $10 off your first purchase and learn more.
            </Text>
            <a
              style={{ textDecoration: "none" }}
              target="__blank"
              href="https://links.go2.modere.com/a/1463/click/157/2218345/41613b2c58ab14a7e54571c02486f31bf6bdff29/6aa2e2e1f48afcca21d4bbc23de846f9269716cd"
            >
              <Button
                color={Colors.white}
                background={Colors.black}
                margin="10px 0 0 0"
              >
                See Supplements
              </Button>
            </a>
          </Column>
        </Box>
      </Grid>
      <Modal show={show} toggleModal={toggleModal}>
        <div
          className="fadeIn calendly-inline-widget"
          data-url="https://calendly.com/bokingperformancefitness"
          style={{
            minWidth: 320,
            height: 630,
            width: "100%",
            // minHeight: "100%",
            // marginBottom: "4em",
            // marginTop: "30px",
          }}
        ></div>
      </Modal>
    </Wrapper>
  )
}
// <a
//   style={{ textDecoration: "none" }}
//   target="__blank"
//   href="https://links.go2.modere.com/a/1463/click/157/2218345/41613b2c58ab14a7e54571c02486f31bf6bdff29/6aa2e2e1f48afcca21d4bbc23de846f9269716cd"
// >
// <Button
//   color={Colors.white}
//   background={Colors.black}
//   margin="10px 0 0 0"
// >
//   See Supplements
// </Button>

// </a>
