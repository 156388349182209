import React from "react"
import styled from "styled-components"
import { Wrapper, Container, Row, Text, Subtitle, Column } from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import { StaticImage } from "gatsby-plugin-image"
import { FaInstagram, FaFacebook } from "react-icons/fa"

const FooterRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
    align-items: center;
    justify-content: center;
`}
  ${Responsive.lg`
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
`}
`

const Divider = styled.div`
  border-bottom: 2px solid #fff;
  width: 50%;
  margin: 0 auto;
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
        margin-top: 30px;
        align-items: center;
        width: 100%;
    `}
  ${Responsive.lg`
    margin-top: 0px;
    align-items: center;
    width: 100%;
`}
`

const StyledLink = styled.a`
  ${Responsive.sm`
cursor: pointer;
margin: ${props => (props.margin ? "0px 15px 0 0" : "0")};
&:hover {
  color: ${Colors.darkBlue};
}
`}
  ${Responsive.lg`
margin-top: 0px;
`}
`

const LogoRow = styled(Row)`
  ${Responsive.sm`
    width: 40%;
    justify-content: center;
    margin-top: 30px;
  `}
  ${Responsive.md`
  width: 25%;
  margin-top: 30px;
`}
  ${Responsive.lg`
  width: 25%;
  margin-top: 0px;
`}
`

export const Footer = () => {
  return (
    <Wrapper black>
      <Container>
        <FooterRow>
          <Column alignitems="center" width="100%">
            <Subtitle white>Follow me on Social Media</Subtitle>
            <Row justifycontent="center" alignitems="center">
              <StyledLink
                margin
                href="https://www.instagram.com/bokingperformancefitness/"
              >
                <FaInstagram color={Colors.white} size={40} />
              </StyledLink>
              <StyledLink
                margin
                href="https://www.facebook.com/bokingperformancefitness"
              >
                <FaFacebook color={Colors.white} size={40} />
              </StyledLink>
            </Row>
          </Column>
          <LogoRow>
            <StaticImage
              src="../images/bokinglogo.png"
              alt="CheshTech"
              placeholder="blurred"
              //   layout="fixed"

              style={{ borderRadius: "50%" }}
              // height={600}
            />
          </LogoRow>
          <StyledColumn>
            <Subtitle margin="0" white>
              Contact Me
            </Subtitle>
            <Text white>Email: getfitwithBPF@gmail.com</Text>
            <Text white>Phone: 917.972.6757</Text>
          </StyledColumn>
        </FooterRow>
        <Column margin="50px 0 0 0" alignitems="center">
          <Divider />
          <Text margin="10px 0 0 0" white>
            © 2022 - BoKing Performance Fitness - All Rights Reserved
          </Text>
          <Text margin="10px 0 0 0" white>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://webwithwill.com"
            >
              Website Design by <strong>WebWithWill</strong>
            </a>
          </Text>
        </Column>
      </Container>
    </Wrapper>
  )
}
