import React, { useState, useEffect } from "react"
import styled from "styled-components"
import {
  Text,
  Column,
  Wrapper,
  Container,
  SEOTitle,
  Button,
  Subtitle,
} from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Modal } from "./index"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;
  margin-top: 50px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  @media (max-width: 769px) {
    display: block;
    // flex-direction: column;
  }
`

const Box = styled.div`
  ${Responsive.sm`
  width: 100%;
  height: 100%;
  padding: 35px;
  border-radius: 12px;
  border: 2px solid #000;
  background: ${Colors.black};
  margin: 30px 0 0 0;
  `}
  ${Responsive.lg`
    margin: 0;
  `}
`

const StyledButton = styled(Button)`
  margin-top: 25px;
  &:hover {
    background: ${Colors.white};
    color: ${Colors.black};
  }
`

export const Services = () => {
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  const images = useStaticQuery(graphql`
    query {
      boxing: file(relativePath: { eq: "boxing.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      basketball: file(relativePath: { eq: "kidsplaying.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      group: file(relativePath: { eq: "group3pkg.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const data = [
    {
      package: "1-on-1 Training",
      price: "Single Session: $75",
      groupPrice: "Package of 5 Sessions: $325",
      description: `60 mins of a tailor-made workout devoted to achieving your fitness goals.`,
      btnText: "Book Class",
      img: images.boxing.childImageSharp.gatsbyImageData,
      alt: "workout",
    },
    {
      package: "3-5 Person Group Training",
      price: "Single Session: $65 per person",
      groupPrice: "Package of 5 Sessions: $275 per person",
      description: `60 mins of curated drills that the group will cycle through, each having a one on one moment with me.`,
      btnText: "Book Class",
      img: images.basketball.childImageSharp.gatsbyImageData,
      alt: "workout",
    },
    {
      package: "Groups of 6+ Training",
      price: "Single Session: $60 per person",
      groupPrice: "Package of 5 Sessions: $250 per person",
      description: `60mins of curated drills that the group will cycle through.`,
      btnText: "Book Class",
      img: images.group.childImageSharp.gatsbyImageData,
      alt: "workout",
    },
  ]

  return (
    <Wrapper id="price">
      <Container>
        <SEOTitle black textAlign="center">
          Your Choice of Training
        </SEOTitle>
        <Grid>
          {data.map((key, index) => {
            const image = getImage(key.img)
            return (
              <Box key={index}>
                <Column>
                  <Column>
                    <GatsbyImage
                      image={image}
                      alt={key.alt}
                      placeholder="blurred"
                      // width={200}
                      // height={200}
                    />
                  </Column>
                  <SEOTitle small textAlign="center" margin="15px 0 15px 0">
                    {key.package}
                  </SEOTitle>
                  <Subtitle small white>
                    {key.price}
                  </Subtitle>
                  <Subtitle small white>
                    {key.groupPrice}
                  </Subtitle>
                  <Text white>{key.description}</Text>
                  <StyledButton onClick={() => toggleModal()}>
                    {key.btnText}
                  </StyledButton>
                </Column>
              </Box>
            )
          })}
        </Grid>
      </Container>
      <Modal show={show} toggleModal={toggleModal}>
        <div
          className="fadeIn calendly-inline-widget"
          data-url="https://calendly.com/bokingperformancefitness"
          style={{
            minWidth: 320,
            height: 630,
            width: "100%",
            // minHeight: "100%",
            // marginBottom: "4em",
            // marginTop: "30px",
          }}
        ></div>
      </Modal>
    </Wrapper>
  )
}
