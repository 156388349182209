import React from "react"
import styled from "styled-components"
import { Column } from "../theme/index"
import { Responsive } from "../theme/styles"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  @media (max-width: 769px) {
    display: block;
  }
`

const StyledImg = styled(BgImage)`
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 50px;
  flex-direction: column;
  background-attachment: fixed;
  &::after {
    background-position: center;
    background-size: contain;
  }
`

// const Line = styled.div`
//   height: 10px;
//   width: 25%;
//   margin: 20px auto 20px auto;
//   background: ${Colors.red};
// `

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    margin-top: 15px;
  `}
  ${Responsive.lg`
  margin-top: 0;
`}
`

export const Accolades = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "fitnessfood.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const pluginImage = getImage(placeholderImage)

  return (
    <StyledImg image={pluginImage}>
      <Grid>
        <StyledColumn>
          <StaticImage
            src="../images/CPR-AED-Certified.jpeg"
            quality={95}
            width={150}
            formats={["auto", "webp"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </StyledColumn>
        <StyledColumn>
          <StaticImage
            src="../images/afaa.jpeg"
            quality={95}
            width={150}
            formats={["auto", "webp"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </StyledColumn>
        <StyledColumn>
          <StaticImage
            src="../images/trainercertification.png"
            quality={95}
            width={150}
            formats={["auto", "webp"]}
            alt="A Gatsby astronaut"
            style={{ marginBottom: `1.45rem` }}
          />
        </StyledColumn>
      </Grid>
    </StyledImg>
  )
}

// <Column>
// <SEOTitle white>5</SEOTitle>
// <Line />
// <Text white>Years of Experience</Text>
// </Column>
