import React from "react"
import styled from "styled-components"
import { Text, Column } from "../theme/index"
import { Colors, Fonts, Responsive } from "../theme/styles"
import { IoMdFitness, IoMdBasketball } from "react-icons/io"
import { GiBoxingGlove } from "react-icons/gi"

const ServiceCard = styled.div`
  ${Responsive.sm`
display: flex;
justify-content: center;
background: ${Colors.red};
align-items: center;
width: 100%;
color: ${Colors.black};
margin-top: 0px;
padding: 20px;
margin-bottom: 30px;
font-family: ${Fonts.montserrat};
margin-right: ${props => (props.marginRight ? "10px" : "0")};
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
transition: 0.3s;
&:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
`}
  ${Responsive.lg`
margin-top: 0px;
margin-bottom: 0px;
`}
`

const ServicesGrid = styled.div`
  ${Responsive.sm`
  width: 90%;
  display: block;
  margin-top: 30px;
  z-index: 10000;
  `}
  ${Responsive.lg`
  display: grid;
  width: 100%;
  grid-gap: 0px;
  margin-top: 0px;
  z-index: 10000;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  `}
`

const StyledText = styled(Text)`
  color: white;
  margin-top: 10px;
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    margin-left: 0px;
  `}
  ${Responsive.lg`
  margin-left: 10px;
`}
`

const data = [
  {
    icon: <IoMdFitness size={60} color={Colors.white} />,
    service: "Get in Shape",
    // list1: "Direct access to our leadership team of experts",
    margin: true,
  },
  {
    icon: <IoMdBasketball size={60} color={Colors.white} />,
    service: "Feel Great",
    // list1: "Conversion centric websites built for fulfilling your goals",
    margin: true,
  },
  {
    icon: <GiBoxingGlove size={60} color={Colors.white} />,
    service: "Be Inspired",
    // list1: "Social media management and scheduling",
    margin: true,
  },
]

export const ValueCards = () => {
  return (
    <Column alignitems="center">
      <ServicesGrid>
        {data.map((key, index) => {
          return (
            <ServiceCard marginRight key={index}>
              <Column
                alignitems="center"
                justifycontent="center"
                width="90%"
                margin="0px auto"
              >
                {key.icon}
                <StyledColumn>
                  <StyledText textAlign="center">{key.service}</StyledText>
                  <StyledText textAlign="center">{key.list1}</StyledText>
                </StyledColumn>
              </Column>
            </ServiceCard>
          )
        })}
      </ServicesGrid>
    </Column>
  )
}
