import React from "react"
import styled from "styled-components"
import { Responsive, Colors, Fonts } from "../theme/styles"
import { Input, Button, Column, Select } from "../theme"

const FormWrapper = styled.div`
  ${Responsive.sm`
width: 100%;
max-width: 737px;
height: auto;
max-width: ${props => props.maxwidth};
border-radius: 12px;
margin: 30px 0 0 0;
border: solid 2px ${Colors.black};
background: ${Colors.white};
padding: ${props => props.padding};
`}
  ${Responsive.md`
width: 75%;
`}
${Responsive.lg`
width: 85%;
`}
`

const Form = styled.form`
  ${Responsive.sm`
width: 100%;
display: flex;
margin: 0 auto;
background: ${Colors.white};
flex-direction: column;
border-radius: 12px;
padding: 32px;
`}
  ${Responsive.lg`
width: 90%;
display: flex;
margin: 0 auto;
flex-direction: column;
padding: 32px;
`}
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: ${Colors.black};
  margin-bottom: 12px;
  width: 100%;
  font-family: ${Fonts.montserrat};
`

const StyledButton = styled(Button)`
  ${Responsive.sm`
    width: 180px;
    margin-top: 20px;
  `}//   ${Responsive.lg`
//   width: 180px;
//   margin-top: 20px;
// `}
`

export const ContactForm = () => {
  return (
    <Column width="100%">
      <FormWrapper>
        <Form
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="contact"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <Label>
            Name
            <Input placeholder="Name" name="name" required />
          </Label>
          <Label>
            Email
            <Input placeholder="Email" type="email" name="email" required />
          </Label>
          <Label>
            What type of training?
            <Select id="training" name="training">
              <option value="slim and tone">Slim and Tone</option>
              <option value="boxing">Boxing</option>
              <option value="basketball">Basketball</option>
              <option value="virtual">Virtual Training</option>
            </Select>
          </Label>
          <Label>
            What package are you interested in?
            <Select id="packages" name="packages">
              <option value="1-on-1">1-on-1 Training</option>
              <option value="3-5 group training">
                3-5 Person Group Training
              </option>
              <option value="6+ group training">6+ Group Training</option>
            </Select>
          </Label>
          <Label>
            Tell Me About Your Goals
            <textarea placeholder="Message" name="message" required />
          </Label>
          <Column width="100%" alignitems="flex-end">
            <StyledButton type="submit">Submit</StyledButton>
          </Column>
        </Form>
      </FormWrapper>
    </Column>
  )
}
// <option value="nutrition">Nutrition Supplements</option>
