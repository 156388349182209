import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Title, Column, Container, Button, SEOTitle, Row } from "../theme/index"
import { Responsive, Colors } from "../theme/styles"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { Modal } from "./index"
import scrollTo from "gatsby-plugin-smoothscroll"

const StyledImg = styled(BgImage)`
  height: 100vh;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::before {
    opacity: 0.2 !important;
  }
  &::after {
    background-position: center;
    background-size: contain;
    opacity: 1 !important;
  }

  @media (max-width: 720px) {
    &::before {
      opacity: 0.3 !important;
    }
    &::after {
      background-position: center;
      background-size: contain;
      opacity: 1 !important;
    }
  }
`

const StyledColumn = styled(Column)`
  width: 100%;
  align-items: center;
  margin-top: 0px;
`

const StyledSEOTitle = styled(SEOTitle)`
  ${Responsive.sm`
    font-size: 22px;
    margin: 30px;
    color: ${Colors.white};
    font-style: italic;
  `}
  ${Responsive.lg`
  font-size: 28px;
`}
`

const StyledTitle = styled(Title)`
  ${Responsive.sm`
font-size: 30px;
text-transform: uppercase;
margin: 0;
color: ${Colors.white};
`}
  ${Responsive.lg`
font-size: 48px;
`}
`

const ButtonRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
  `}
  ${Responsive.lg`
  flex-direction: row;
`}
`

const StyledButton = styled(Button)`
  ${Responsive.sm`
    margin-top: 10px;
    margin-left: 0;
  `}
  ${Responsive.lg`
  margin-top: 0;
  margin-left: 10px;
`}
`

export const HeroSplash = ({ title, type, subtitle, btnText, text }) => {
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero/bokingheroimg.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const pluginImage = getImage(placeholderImage)

  return (
    <StyledImg
      image={pluginImage}
      type={type}
      id="home"
      preserveStackingContext={true}
    >
      <Container>
        <StyledColumn>
          <StyledTitle splash>{title}</StyledTitle>
          <StyledSEOTitle>{subtitle}</StyledSEOTitle>
          <ButtonRow>
            <Button onClick={() => toggleModal()}>{btnText}</Button>
            <StyledButton margin="0 0 0 10px" onClick={() => scrollTo("#cta")}>
              {text}
            </StyledButton>
          </ButtonRow>
        </StyledColumn>
      </Container>
      <Modal show={show} toggleModal={toggleModal}>
        <div
          className="fadeIn calendly-inline-widget"
          data-url="https://calendly.com/bokingperformancefitness"
          style={{
            minWidth: 320,
            height: 630,
            width: "100%",
            // minHeight: "100%",
            // marginBottom: "4em",
            // marginTop: "30px",
          }}
        ></div>
      </Modal>
    </StyledImg>
  )
}
