import { css } from "styled-components"

export const Colors = {
  lightBlue: "#4ca1af",
  red: "#f89926",
  white: "#ffffff",
  black: "#000000",
  gray: "#f2f2f2",
}

export const Fonts = {
  raleway: `Allerta Stencil, sans-serif`,
  // montserrat: 'Playfair Display, serif',
  montserrat: `Montserrat, sans-serif`,
}

export const Responsive = {
  sm: (...args) => css`
    @media (min-width: 320px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: 767px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: 1024px) {
      ${css(...args)};
    }
  `,
  xlg: (...args) => css`
    @media (min-width: 1400px) {
      ${css(...args)};
    }
  `,
}
