import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import {
  Text,
  Column,
  Wrapper,
  Container,
  SEOTitle,
  Button,
  Subtitle,
} from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Modal } from "./index"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;
  margin-top: 100px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  @media (max-width: 769px) {
    display: block;
    margin-top: 50px;
  }
`

// const Box = styled.div`
//   width: 100%;
//   height: 100%;
//   padding: 25px;
//   border-radius: 12px;
//   border: 2px solid ${Colors.red};
//   background: ${Colors.white};
//   position: relative;
//   ${props => (props.margintop ? "50px" : "0")};
// `

const NumberBox = styled.div`
  ${Responsive.sm`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${Colors.red};
  border: 2px solid ${Colors.black};
  padding: 10px;
  width: 75px;
  height: 75px;
  color: ${Colors.black};
  margin: 0px auto 20px auto;
  `}
  ${Responsive.lg`
  width: 60px;
  height: 60px;
  `}
  ${Responsive.xlg`
  width: 100px;
  height: 100px;
  `}
`

export const Plan = () => {
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  const data = [
    {
      number: "1",
      title: "Book Class",
      text: "Contact me today to schedule your class to begin your fitness journey",
      margin: false,
      button: true,
    },
    {
      number: "2",
      title: "Come to Training",
      text: "Show up to training ready to sweat and prepared to get the best out of yourself.",
      margin: true,
      button: false,
    },
    {
      number: "3",
      title: "Feel and Look Your Best",
      text: "Start reaping the emotional, physical, and mental benefits of feeling and looking your best.",
      margin: true,
      button: false,
    },
  ]

  return (
    <Wrapper gray>
      <Container>
        <SEOTitle black textAlign="center">
          It's As Easy As 1-2-3
        </SEOTitle>
        <Grid>
          <Column width="80%" margin="0 auto">
            <StaticImage
              src="../images/jonwithclient.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="A Gatsby astronaut"
              style={{ marginBottom: `1.45rem` }}
            />
          </Column>
          <Column>
            {data.map((key, index) => {
              return (
                <Column margin="0 0 30px 0" key={index}>
                  <NumberBox>
                    <Subtitle margin="0" fontweight="bold" black>
                      {key.number}
                    </Subtitle>
                  </NumberBox>
                  <Subtitle black>{key.title}</Subtitle>
                  <Text width="70%" textAlign="center" black>
                    {key.text}
                  </Text>
                  {key.button === true && (
                    <Button margin="15px 0 0 0" onClick={() => toggleModal()}>
                      Book Class
                    </Button>
                  )}
                </Column>
              )
            })}
          </Column>
        </Grid>
        <Column margin="30px 0 0 0">
          <Button onClick={() => scrollTo("#cta")}>
            Contact For Free Consult
          </Button>
        </Column>
      </Container>
      <Modal show={show} toggleModal={toggleModal}>
        <div
          className="fadeIn calendly-inline-widget"
          data-url="https://calendly.com/bokingperformancefitness"
          style={{
            minWidth: 320,
            height: 630,
            width: "100%",
            // minHeight: "100%",
            // marginBottom: "4em",
            // marginTop: "30px",
          }}
        ></div>
      </Modal>
    </Wrapper>
  )
}
