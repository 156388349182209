import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import NavLogo from "../images/bokinglogo.png"
import { Link } from "gatsby"
import { Row, Button } from "../theme/index"
import { Colors, Responsive, Fonts } from "../theme/styles"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Modal } from "./index"

const Wrapper = styled.div`
  ${Responsive.sm`
  background: ${props => props.background};
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100000;
  padding: 60px 0;
  transition: 250ms;
  `}
  ${Responsive.lg`
  background: ${props => props.background};
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100000;
  padding: 0;
  transition: 250ms;
  `}
`

const Logo = styled.img`
  width: 75px;
  margin-bottom: 0px;
  border-radius: 50%;
  @media (max-width: 720px) {
    width: 50px;
  }
`

const StyledRow = styled(Row)`
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const NavRow = styled(Row)`
  ${Responsive.sm`
  margin-bottom: 10px;
  width: 100%;
  align-items: center;
  `}
  ${Responsive.md`
    width: auto;
    margin-bottom: 0;
  `}
`

const StyledButton = styled(Button)`
  ${Responsive.sm`
    width: 100%;
    height: 40px;
    padding: 10px;
    margin: 0 0 0 1em;
    &:hover {
      background: ${Colors.white};
      color: ${Colors.black};
    }
  `}
  ${Responsive.md`
  width: 180px;
  height: 100%;
  `}
  ${Responsive.lg`
  margin: 0 0 0 2.25em;
`}
`

const ScrollLink = styled.p`
  color: ${props => props.color};
  font-size: 16px;
  margin-left: ${props => (props.left ? "0" : "2.25em")};
  font-family: ${Fonts.montserrat};
  text-decoration: none;
  padding: 0.25em 0;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  ${props =>
    props.home &&
    css`
      color: ${Colors.green};
    `};
  @media (max-width: 769px) {
    font-size: 12px;
    padding: ${props => (props.left ? "0" : ".25em")};
    margin-left: ${props => (props.left ? "0" : "1em")};
  }
  @media (max-width: 450px) {
    font-size: 10px;
    margin-left: ${props => (props.left ? "0" : "7px")};
  }
  @media (max-width: 380px) {
    font-size: 10px;
  }
  &:active {
    color: $;
  }
`

export const Navigation = () => {
  const [color, setNavColor] = useState("rgba(0,0,0,0)")
  const [linkColor, setLinkColor] = useState(`${Colors.white}`)
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  })

  const listenScrollEvent = e => {
    if (window.scrollY > 75) {
      setNavColor(`${Colors.black}`)
      setLinkColor(`${Colors.white}`)
    } else {
      setNavColor("rgba(0,0,0,0)")
      setLinkColor(`${Colors.white}`)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)

    return () => {
      window.removeEventListener("scroll", listenScrollEvent)
    }
  }, [])

  return (
    <Wrapper background={color}>
      <StyledRow>
        <Row alignitems="center">
          <Link to="/">
            <Logo
              src={NavLogo}
              alt="CheshTech Digital Agency: Digital Marketing and Websites for Latino Entrepreneurs"
            />
          </Link>
        </Row>
        <NavRow>
          <ScrollLink onClick={() => scrollTo("#home")} color={linkColor}>
            Home
          </ScrollLink>
          <ScrollLink onClick={() => scrollTo("#about")} color={linkColor}>
            About
          </ScrollLink>
          <ScrollLink onClick={() => scrollTo("#services")} color={linkColor}>
            Services
          </ScrollLink>
          <ScrollLink onClick={() => scrollTo("#price")} color={linkColor}>
            Price/Packages
          </ScrollLink>
          <StyledButton onClick={() => toggleModal()}>Book Class</StyledButton>
        </NavRow>
        <Modal show={show} toggleModal={toggleModal}>
          <div
            className="fadeIn calendly-inline-widget"
            data-url="https://calendly.com/bokingperformancefitness"
            style={{
              minWidth: 320,
              height: 630,
              width: "100%",
              // minHeight: "100%",
              // marginBottom: "4em",
              // marginTop: "30px",
            }}
          ></div>
        </Modal>
      </StyledRow>
    </Wrapper>
  )
}
