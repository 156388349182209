import React, { Fragment } from "react"
import styled from "styled-components"
import {
  Text,
  Column,
  SEOTitle,
  Subtitle,
  Row,
  Wrapper,
  Container,
} from "../theme/index"
import { IoMdCheckmarkCircle } from "react-icons/io"
import { Colors, Responsive } from "../theme/styles"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-items: center;
  @media (max-width: 767px) {
    display: block;
  }
`

const StyledSEOTitle = styled(SEOTitle)`
  ${Responsive.sm`
    width: 100%;
    text-align: center;
  `}
  ${Responsive.lg`
  width: 80%;
`}
  ${Responsive.xlg`
  width: 60%;
`}
`
const StyledText = styled(Text)`
  ${Responsive.sm`
    margin: 10px 0 0 10px;
    text-align: left;
    font-size: 16px;
  `}
  ${Responsive.lg`
  margin: 10px 0 0 10px;
  font-size: 18px;
  `}
`

const left = [
  {
    icon: <IoMdCheckmarkCircle size={30} color={Colors.red} />,
    text: "Workouts designed to maximize your time and effort",
  },
  {
    icon: <IoMdCheckmarkCircle size={30} color={Colors.red} />,
    text: "Circuit training that ignites major calorie burn",
  },
  {
    icon: <IoMdCheckmarkCircle size={30} color={Colors.red} />,
    text: "Drills devised to push your limits and enhance your skills",
  },
]

const right = [
  {
    icon: <IoMdCheckmarkCircle size={30} color={Colors.red} />,
    text: "Options to train virtually, one on one, or with friends",
  },
  {
    icon: <IoMdCheckmarkCircle size={30} color={Colors.red} />,
    text: "Never get bored",
  },
  {
    icon: <IoMdCheckmarkCircle size={30} color={Colors.red} />,
    text: "Get results fast!",
  },
]

export const ValueProps = () => {
  return (
    <Wrapper>
      <Container>
        <Column>
          <StyledSEOTitle>Train and Transform</StyledSEOTitle>
          <Subtitle margin="15px 0 25px 0">
            What to expect from BPF Training
          </Subtitle>
          <Grid>
            <Column>
              {left.map((key, index) => {
                return (
                  <Fragment key={index}>
                    <Row alignitems="center" width="100%">
                      <Column>{key.icon}</Column>
                      <StyledText>{key.text}</StyledText>
                    </Row>
                  </Fragment>
                )
              })}
            </Column>
            <Column>
              {right.map((key, index) => {
                return (
                  <Fragment key={index}>
                    <Row alignitems="center" width="100%">
                      <Column>{key.icon}</Column>
                      <StyledText>{key.text}</StyledText>
                    </Row>
                  </Fragment>
                )
              })}
            </Column>
          </Grid>
        </Column>
      </Container>
    </Wrapper>
  )
}
