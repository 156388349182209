import React from "react"
import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import { Responsive, Colors } from "../theme/styles"
import { SEOTitle } from "../theme"

// MODAL ANIMATION
const Backdrop = styled.div`
  ${Responsive.sm`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    background-color: rgba(9, 9, 9, 0.8);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;
`}
`

const Div = styled.div`
  ${Responsive.sm`
width: 95%;
// height: ${props => props.height || "95%"};
height: 90%;
border-radius: 20px;
background-color: #fff;
overflow-y: scroll;
right: 0;
bottom: 0;
padding: 20px;
z-index: 12;
`}
  ${Responsive.md`
width: 600px;
`}
  ${Responsive.lg`
width: 700px;
height: 90%;
`}
`

const StyledClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f2f5f9;
  border-radius: 50%;
  cursor: pointer;
  transition: 500ms;
  &:hover {
    background-color: ${Colors.red};
  }
`

export const Modal = ({ show, children, toggleModal, height }) => {
  if (!show) {
    return null
  }
  return (
    <Backdrop>
      <Div height={height}>
        <StyledClose type="button" onClick={toggleModal}>
          <FaTimes size={20} />
        </StyledClose>
        <SEOTitle black textAlign="center" margin="0 0 10px 0">
          Book Class
        </SEOTitle>
        {children}
      </Div>
    </Backdrop>
  )
}
