import React from "react"
import styled from "styled-components"
import { Subtitle, SEOTitle, Button } from "../theme/index"
import { Colors } from "../theme/styles"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { graphql, useStaticQuery } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

const StyledImg = styled(BgImage)`
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 50px;
  flex-direction: column;
  background-attachment: fixed;
  &::after {
    background-position: center;
    background-size: contain;
  }
`

const Line = styled.div`
  height: 10px;
  width: 50%;
  margin: 20px auto 20px auto;
  background: ${Colors.red};
`

export const BodyCTA = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "midhero.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const pluginImage = getImage(placeholderImage)

  return (
    <StyledImg image={pluginImage}>
      <SEOTitle white>Get Started With A Free Consultation</SEOTitle>
      <Line />
      <Subtitle white>Optimize Your Physical Capabilities</Subtitle>
      <Button margin="15px" onClick={() => scrollTo("#cta")}>
        Contact For Free Consult
      </Button>
    </StyledImg>
  )
}
