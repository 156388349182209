import React from "react"
import styled from "styled-components"
import { Text, Column, Wrapper, Container, SEOTitle } from "../theme/index"
import { Responsive } from "../theme/styles"
import { graphql, useStaticQuery } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Grid = styled.div`
  ${Responsive.sm`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  `}
  ${Responsive.lg`
  display: grid;
  margin-top: 50px;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  `}
  ${Responsive.xlg`
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  `}
`

const TestimonialBox = styled.div`
  ${Responsive.sm`
  width: 100%;
  max-width: 380px;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
  background-color: #ffffff;
  margin-right: ${props => (props.marginright ? "40px" : "0px")};
  `}
  ${Responsive.md`
  margin-top: 20px;
  `}
  @media (max-width: 950px) {
    height: 100%;
    margin-top: 20px;
    margin-right: ${props => (props.marginright ? "0px" : "0px")};
  }
  @media (max-width: 769px) {
    margin-top: 20px;
    width: 100%;
    margin-right: ${props => (props.marginright ? "0px" : "0px")};
  }
`

// const UserOval = styled(GatsbyImage)`
//   width: 88px;
//   height: 88px;
//   border-radius: 50%;
//   margin-right: 16px;
//   @media (max-width: 1200px) {
//     margin-right: 0px;
//   }
// `

const StyledText = styled(Text)`
  text-align: left;
  margin-top: 10px;
  @media (max-width: 950px) {
    text-align: center;
  }
`

// const PersonRow = styled(Row)`
//   margin: 0 0 10px 0;
//   align-items: center;
//   @media (max-width: 1200px) {
//     flex-direction: column;
//   }
// `

export const Testimonials = () => {
  const images = useStaticQuery(graphql`
    query {
      whiteguys: file(relativePath: { eq: "personaltrainers.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      women: file(relativePath: { eq: "womanworkingout.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      blackguy: file(relativePath: { eq: "medicineball.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const data = [
    {
      name: "-Caren",
      title: "Youth Basketball Training",
      comment: `"Coach Jon has been training my son and his friends in basketball since September 2020. They are learning so much about teamwork, physical health, challenging themselves mentally and basketball strategy. He has an amazing ability to motivate kids with constructive, positive instruction. He’s got them locked in, fired up and working hard to improve. Best coach out there!"`,
      img: images.whiteguys.childImageSharp.gatsbyImageData,
      alt: "workout",
    },
    {
      name: "-Christina",
      title: "Personal Training",
      comment: `"Jon continually challenges me while also adapting workouts when I struggle with certain movements or exercises. Because of that, I stay focused and see results. My technique and stamina have improved dramatically."`,
      img: images.whiteguys.childImageSharp.gatsbyImageData,
      alt: "workout",
    },
    {
      name: "-Tricia",
      title: "Personal Training",
      comment: `"Since training with Jon, I have more energy, more confidence, my clothes fit better. He is very concise about what our workouts are so it saves time and the headache of figuring out what I need to do. I look forward to training with him every week. I feel like it is absolutely a part of who I am now!"`,
      img: images.whiteguys.childImageSharp.gatsbyImageData,
      alt: "workout",
    },
  ]

  return (
    <Wrapper>
      <Container>
        <SEOTitle black textAlign="center">
          What My Clients Say
        </SEOTitle>
        <Grid>
          {data.map((key, index) => {
            // const image = getImage(key.img)
            return (
              <TestimonialBox marginright key={index}>
                <Column margin="32px">
                  <div>
                    <Column>
                      <Text fontweight="bold" textAlign="center">
                        {key.title}
                      </Text>
                    </Column>
                    <StyledText lineheight>{key.comment}</StyledText>
                    <Text
                      fontweight="bold"
                      textalign="center"
                      margin="10px 0 2px 0"
                    >
                      {key.name}
                    </Text>
                  </div>
                </Column>
              </TestimonialBox>
            )
          })}
        </Grid>
      </Container>
    </Wrapper>
  )
}
//   <UserOval src={data.img} style={{ marginBottom: "10px" }} />
// <PersonRow>
//   <UserOval
//     image={image}
//     alt={key.alt}
//     placeholder="blurred"
//   />

//   <Column>
//     <Text
//       fontweight="bold"
//       textalign="center"
//       margin="10px 0 2px 0"
//     >
//       {key.name}
//     </Text>
//     <Text textalign="center">{key.title}</Text>
//   </Column>
// </PersonRow>
